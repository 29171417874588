import { createRouter, createWebHashHistory } from "vue-router";
import Login from "@/components/global/LoginView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    // route level code-splitting
    // this generates a separate chunk (home.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
    meta: { transition: "fade", breadcrumb: "Home" },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/setup",
    name: "setup",
    component: () =>
      import(/* webpackChunkName: "setup" */ "../views/GlbSetup.vue"),
    meta: { transition: "fade", breadcrumb: "GLB Setup;" },
  },
  {
    path: "/:notFound",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue"),
    meta: { transition: "fade", breadcrumb: "404" },
  },
  {
    path: "/hubner-group",
    component: () =>
      import(
        /* webpackChunkName: "hubner-group" */ "../components/global/EmptyRouterView.vue"
      ),
    meta: { transition: "fade", breadcrumb: "HÜBNER Group" },
    children: [
      {
        path: "",
        name: "hubner-group",
        component: () =>
          import(
            /* webpackChunkName: "hubner-group" */ "../views/hubner-group/AboutView.vue"
          ),
        meta: {
          transition: "fade",
          breadcrumb: "About us",
        },
      },
      {
        path: "about-us",
        name: "about-us",
        component: () =>
          import(
            /* webpackChunkName: "about-us" */ "../views/hubner-group/AboutView.vue"
          ),
        meta: {
          transition: "fade",
          breadcrumb: "About us",
        },
      },
      {
        path: "sustainability",
        name: "sustainability",
        component: () =>
          import(
            /* webpackChunkName: "sustainability" */ "../views/hubner-group/SustainabilityView.vue"
          ),
        meta: {
          transition: "fade",
          breadcrumb: "Sustainability",
        },
      },
      {
        path: "customer-promise",
        name: "customer-promise",
        component: () =>
          import(
            /* webpackChunkName: "customer-promise" */ "../views/hubner-group/CustomerPromise.vue"
          ),
        meta: {
          transition: "fade",
          breadcrumb: "Customer promise",
        },
      },
      {
        path: "high-capacity-steering-systems",
        name: "high-capacity-steering-systems",
        component: () =>
          import(
            /* webpackChunkName: "high-capacity-steering-systems" */ "../views/hubner-group/HighCapacitySteeringSystems.vue"
          ),
        meta: {
          transition: "fade",
          breadcrumb: "High-Capacity Steering Systems",
        },
      },
    ],
  },
  {
    path: "/innovations",
    component: () =>
      import(
        /* webpackChunkName: "empty-router-view" */ "../components/global/EmptyRouterView.vue"
      ),
    meta: { transition: "fade", breadcrumb: "Innovations" },
    children: [
      {
        path: "",
        name: "innovations",
        component: () =>
          import(
            /* webpackChunkName: "innovations" */ "../views/InnovationsPage.vue"
          ),
        meta: { transition: "fade", breadcrumb: "Innovations" },
      },
    ],
  },
  {
    path: "/products",
    component: () =>
      import(
        /* webpackChunkName: "empty-router-view" */ "../components/global/EmptyRouterView.vue"
      ),
    meta: { transition: "fade", breadcrumb: "Products" },
    children: [
      {
        path: "",
        name: "products",
        component: () =>
          import(
            /* webpackChunkName: "products" */ "../views/ProductsView.vue"
          ),
        meta: { transition: "fade", breadcrumb: "Product Overview" },
      },
      {
        path: "front-end-solutions",
        component: () =>
          import(
            /* webpackChunkName: "empty-router-view" */ "../components/global/EmptyRouterView.vue"
          ),
        meta: {
          transition: "fade",
          breadcrumb: "Front End Solutions",
          emptyView: true,
        },
        children: [
          {
            path: "front-add-system",
            name: "front-add-system",
            component: () =>
              import(
                /* webpackChunkName: "front-add-system" */ "../views/products/FrontAddSystem.vue"
              ),
            meta: {
              transition: "fade",
              breadcrumb: "HÜBNER FrontAdd System",
            },
          },
          {
            path: "front-bumper",
            name: "front-bumper",
            component: () =>
              import(
                /* webpackChunkName: "front-bumper" */ "../views/products/FrontBumper.vue"
              ),
            meta: { transition: "fade", breadcrumb: "Front Bumper" },
          },
          {
            path: "cockpit-displays-and-driver-assistance-systems",
            name: "cockpit-displays-and-driver-assistance-systems",
            component: () =>
              import(
                /* webpackChunkName: "cockpit-displays-and-driver-assistance-systems" */ "../views/products/CockpitDisplaysAndDriverAssistanceSystems.vue"
              ),
            meta: {
              transition: "fade",
              breadcrumb: "Cockpit Displays",
            },
          },
        ],
      },
      {
        path: "intercar-solutions",
        component: () =>
          import(
            /* webpackChunkName: "empty-router-view" */ "../components/global/EmptyRouterView.vue"
          ),
        meta: {
          transition: "fade",
          breadcrumb: "Intercar Solutions",
        },
        children: [
          {
            path: "",
            name: "intercar-solutions",
            component: () =>
              import(
                /* webpackChunkName: "intercar-solutions" */ "../views/products/IntercarSolutions.vue"
              ),
            meta: {
              transition: "fade",
              breadcrumb: "Intercar Solutions",
            },
          },
          {
            path: "light-rail-vehicles",
            name: "light-rail-vehicles",
            component: () =>
              import(
                /* webpackChunkName: "light-rail-vehicles" */ "../views/products/LightRailVehicles.vue"
              ),
            meta: {
              transition: "fade",
              breadcrumb: "Light Rail Vehicles",
            },
          },
          {
            path: "metro",
            name: "metro",
            component: () =>
              import(
                /* webpackChunkName: "metro" */ "../views/products/MetroPage.vue"
              ),
            meta: {
              transition: "fade",
              breadcrumb: "Metro",
            },
          },
          {
            path: "regional",
            name: "regional",
            component: () =>
              import(
                /* webpackChunkName: "regional" */ "../views/products/RegionalPage.vue"
              ),
            meta: {
              transition: "fade",
              breadcrumb: "Regional Trains",
            },
          },
          {
            path: "highspeed",
            name: "highspeed",
            component: () =>
              import(
                /* webpackChunkName: "highspeed" */ "../views/products/HighspeedPage.vue"
              ),
            meta: {
              transition: "fade",
              breadcrumb: "Highspeed Trains",
            },
          },
          {
            path: "passenger-coaches",
            name: "passenger-coaches",
            component: () =>
              import(
                /* webpackChunkName: "passenger-coaches" */ "../views/products/PassengerCoaches.vue"
              ),
            meta: {
              transition: "fade",
              breadcrumb: "Passenger Coaches",
            },
          },
          {
            path: "monorails",
            name: "monorails",
            component: () =>
              import(
                /* webpackChunkName: "monorails" */ "../views/products/MonorailsPage.vue"
              ),
            meta: {
              transition: "fade",
              breadcrumb: "Monorails",
            },
          },
          {
            path: "after-sales-and-service-gangways",
            name: "after-sales-and-service-gangways",
            component: () =>
              import(
                /* webpackChunkName: "after-sales-and-service-gangways" */ "../views/products/AfterSalesAndServiceGangways.vue"
              ),
            meta: {
              transition: "fade",
              breadcrumb: "After Sales & Service Gangways",
            },
          },
          {
            path: "huebner-autocouple-system",
            name: "huebner-autocouple-system",
            component: () =>
              import(
                /* webpackChunkName: "huebner-autocouple-system" */ "../views/products/AutomaticCouplingGangways.vue"
              ),
            meta: {
              transition: "fade",
              breadcrumb: "HÜBNER AutoCouple System",
            },
          },
        ],
      },
      {
        path: "bogie-solutions",
        component: () =>
          import(
            /* webpackChunkName: "empty-router-view" */ "../components/global/EmptyRouterView.vue"
          ),
        meta: {
          transition: "fade",
          breadcrumb: "Bogie Solutions",
          emptyView: true,
        },
        children: [
          {
            path: "",
            name: "bogie-solutions",
            component: () =>
              import(
                /* webpackChunkName: "bogie-solutions" */ "../views/products/BogieSolutions.vue"
              ),
            meta: {
              transition: "fade",
              breadcrumb: "Bogie Solutions",
            },
          },
        ],
      },
      {
        path: "window-solutions",
        component: () =>
          import(
            /* webpackChunkName: "empty-router-view" */ "../components/global/EmptyRouterView.vue"
          ),
        meta: {
          transition: "fade",
          breadcrumb: "Window Solutions",
          emptyView: true,
        },
        children: [
          {
            path: "",
            name: "window-solutions",
            component: () =>
              import(
                /* webpackChunkName: "window-solutions" */ "../views/products/WindowSolutions.vue"
              ),
            meta: {
              transition: "fade",
              breadcrumb: "Window Solutions",
            },
          },
        ],
      },
      {
        path: "insulation-solutions",
        component: () =>
          import(
            /* webpackChunkName: "empty-router-view" */ "../components/global/EmptyRouterView.vue"
          ),
        meta: {
          transition: "fade",
          breadcrumb: "Insulation Solutions",
          emptyView: true,
        },
        children: [
          {
            path: "",
            name: "insulation-solutions",
            component: () =>
              import(
                /* webpackChunkName: "insulation-solutions" */ "../views/products/InsulationSolutions.vue"
              ),
            meta: {
              transition: "fade",
              breadcrumb: "Insulation Solutions",
            },
          },
        ],
      },
      {
        path: "after-sales-and-service-solutions",
        component: () =>
          import(
            /* webpackChunkName: "empty-router-view" */ "../components/global/EmptyRouterView.vue"
          ),
        meta: {
          transition: "fade",
          breadcrumb: "After Sales & Service Solutions",
          emptyView: true,
        },
        children: [
          {
            path: "",
            name: "after-sales-and-service-solutions",
            component: () =>
              import(
                /* webpackChunkName: "after-sales-and-service-solutions" */ "../views/products/AfterSalesAndServiceSolutions.vue"
              ),
            meta: {
              transition: "fade",
              breadcrumb: "After Sales & Service Solutions",
            },
          },
        ],
      },
      {
        path: "hub-link-digital-service",
        component: () =>
          import(
            /* webpackChunkName: "empty-router-view" */ "../components/global/EmptyRouterView.vue"
          ),
        meta: {
          transition: "fade",
          breadcrumb: "Digital Services",
          emptyView: true,
        },
        children: [
          {
            path: "",
            name: "digital-services",
            component: () =>
              import(
                /* webpackChunkName: "digital-services" */ "../views/products/DigitalServices.vue"
              ),
            meta: {
              transition: "fade",
              breadcrumb: "Digital Services",
            },
          },
        ],
      },
      {
        path: "entry-solutions",
        name: "entry-solutions",
        component: () =>
          import(
            /* webpackChunkName: "empty-router-view" */ "../components/global/EmptyRouterView.vue"
          ),
        meta: {
          transition: "fade",
          breadcrumb: "Entry Solutions",
          emptyView: true,
        },
        children: [
          {
            path: "ramps",
            name: "ramps",
            component: () =>
              import(
                /* webpackChunkName: "ramps" */ "../views/products/RampsPage.vue"
              ),
            meta: {
              transition: "fade",
              breadcrumb: "Ramps",
            },
          },
          {
            path: "door-safety-and-sealing-systems",
            name: "door-safety-and-sealing-systems",
            component: () =>
              import(
                /* webpackChunkName: "door-safety-and-sealing-systems" */ "../views/products/DoorSafetyAndSealingSystems.vue"
              ),
            meta: {
              transition: "fade",
              breadcrumb: "Door Safety & Sealing Systems",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/affiliates",
    component: () =>
      import(
        /* webpackChunkName: "empty-router-view" */ "../components/global/EmptyRouterView.vue"
      ),
    meta: { transition: "fade", breadcrumb: "Affiliates" },
    children: [
      {
        path: "",
        name: "affiliates",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
        meta: { transition: "fade", breadcrumb: "Affiliates" },
      },
      {
        path: "hubner-transportation",
        name: "hubner-transportation",
        component: () =>
          import(
            /* webpackChunkName: "hubner-transportation" */ "../views/affiliates/HubnerTransportation.vue"
          ),
        meta: {
          transition: "fade",
          breadcrumb: "HÜBNER Transportation",
        },
      },
      {
        path: "atg",
        name: "atg",
        component: () =>
          import(
            /* webpackChunkName: "atg" */ "../views/affiliates/AtgAutotechnik.vue"
          ),
        meta: {
          transition: "fade",
          breadcrumb: "ATG Autotechnik",
        },
      },
      {
        path: "hemscheidt",
        name: "hemscheidt",
        component: () =>
          import(
            /* webpackChunkName: "hemscheidt" */ "../views/affiliates/HemScheidt.vue"
          ),
        meta: {
          transition: "fade",
          breadcrumb: "HEMSCHEIDT Engineering",
        },
      },
      {
        path: "gersys",
        name: "gersys",
        component: () =>
          import(
            /* webpackChunkName: "gersys" */ "../views/affiliates/GerSys.vue"
          ),
        meta: {
          transition: "fade",
          breadcrumb: "GERSYS",
        },
      },
      {
        path: "plastocell",
        name: "plastocell",
        component: () =>
          import(
            /* webpackChunkName: "plastocell" */ "../views/affiliates/PlastoCell.vue"
          ),
        meta: {
          transition: "fade",
          breadcrumb: "Plastocell",
        },
      },
      {
        path: "kt-hennigsdorf",
        name: "kt-hennigsdorf",
        component: () =>
          import(
            /* webpackChunkName: "kt-hennigsdorf" */ "../views/affiliates/KtHennigsdorf.vue"
          ),
        meta: {
          transition: "fade",
          breadcrumb: "Kunststofftechnik Hennigsdorf",
        },
      },
    ],
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  return new Promise((resolve) => {
    if (to.hash) {
      const scrollToElement = () => {
        const element = document.querySelector(to.hash);
        if (element) {
          const yOffset = 0;
          const y =
            element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
          resolve();
        }
      };

      setTimeout(scrollToElement, 500);
      setTimeout(scrollToElement, 100);
    } else if (savedPosition) {
      resolve(savedPosition);
    } else {
      resolve({ top: 0 });
    }
  });
};

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  if (to.name !== "Login" && !isAuthenticated) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
