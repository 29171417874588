<script setup>
import { ref } from "vue";

const props = ref({});
</script>

<template>
  <h3
    v-bind="props"
    class="text-4xl font-light !leading-tight normal-case tracking-normal text-primary-500"
  >
    <slot></slot>
  </h3>
</template>
