<script setup>
import { computed } from "vue";

const props = defineProps({
  class: {
    type: String,
    default: "text-black",
  },
  size: {
    type: String,
    required: false,
  },
});

const defaultClass = "text-neutral-600 text-2xl !leading-[150%] font-light";

const sizeClass = computed(() => {
  return {
    small: "text-lg desktop:text-base font-light",
    large: "text-lg desktop:text-[1.375rem] font-normal",
    bold: "text-lg desktop:text-[1.375rem] font-bold",
    default: "text-neutral-600 text-2xl !leading-[150%] font-light",
  }[props.size || "default"];
});

const computedClass = computed(() => {
  return `${sizeClass.value} ${defaultClass} ${props.class}`;
});
</script>

<template>
  <p :class="computedClass">
    <slot></slot>
  </p>
</template>
