<script setup>
import { computed } from "vue";

const props = defineProps({
  class: {
    type: String,
    default: "text-black",
  },
  size: {
    type: String,
    required: false,
  },
});

const defaultClass =
  "!leading-loose normal-case tracking-normal text-neutral-950";

const sizeClass = computed(() => {
  return {
    small: "text-xl desktop:text-lg steleSmall:text-lg font-light",
    middle: "text-xl desktop:text-[22px] steleSmall:text-[22px] font-light",
    large: "text-xl desktop:text-2xl steleSmall:text-2xl font-normal",
    bold: "text-xl desktop:text-2xl steleSmall:text-2xl font-bold",
    default: "text-xl desktop:text-2xl steleSmall:text-2xl font-light",
  }[props.size || "default"];
});

const computedClass = computed(() => {
  return `${sizeClass.value} ${defaultClass} ${props.class}`;
});
</script>

<template>
  <p :class="computedClass">
    <slot></slot>
  </p>
</template>
