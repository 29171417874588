<script setup>
import { ref } from "vue";

const props = ref({});
</script>

<template>
  <h5
    v-bind="props"
    class="text-[1.75rem] font-medium !leading-tight normal-case tracking-normal text-primary-500"
  >
    <slot></slot>
  </h5>
</template>
