// Package imports
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger.js";
// import fslightbox from "fslightbox";

// CSS import
import "./assets/app.css";

// Vue app imports
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// Global components
import EmptyRouterView from "@/components/global/EmptyRouterView.vue";
import PageDisplay from "@/components/typography/PageDisplay.vue";
import PageH1 from "@/components/typography/PageH1.vue";
import PageH2 from "@/components/typography/PageH2.vue";
import PageH3 from "@/components/typography/PageH3.vue";
import PageH4 from "@/components/typography/PageH4.vue";
import PageH5 from "@/components/typography/PageH5.vue";
import PageH6 from "@/components/typography/PageH6.vue";
import PageParagraph from "@/components/typography/PageParagraph.vue";
import PageParagraphTitle from "@/components/typography/PageParagraphTitle.vue";
import PageParagraphIntro from "@/components/typography/PageParagraphIntro.vue";

// Create Vue.js app
const app = createApp(App);

// Register global components
app.component("EmptyRouterView", EmptyRouterView);
app.component("PageDisplay", PageDisplay);
app.component("PageH1", PageH1);
app.component("PageH2", PageH2);
app.component("PageH3", PageH3);
app.component("PageH4", PageH4);
app.component("PageH5", PageH5);
app.component("PageH6", PageH6);
app.component("PageParagraph", PageParagraph);
app.component("PageParagraphTitle", PageParagraphTitle);
app.component("PageParagraphIntro", PageParagraphIntro);

app.directive("smooth-scroll", {
  mounted(el, binding) {
    el.addEventListener("click", (e) => {
      e.preventDefault();
      const target = document.getElementById(binding.value);
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    });
  },
});

// Use router
app.use(router);

// Mount Vue.js app
app.mount("#app");

// Lightbox instance
// refreshFsLightbox();

// Determine path length of SVG line items for animation
// window.onload = function () {
//   const pathElement = document.getElementById("line-door-safety-systems");
//   const pathLength = pathElement.getTotalLength();

//   // You can now use the pathLength value in your CSS or JavaScript as needed
//   console.log("Path length:", pathLength);
// };

// Coded by unlimited.studio – https://unlimited.studio
