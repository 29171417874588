<script setup>
import { ref } from "vue";

const props = ref({});
</script>

<template>
  <h2
    v-bind="props"
    class="text-4xl desktop:text-5xl steleSmall:text-5xl font-bold !leading-tight normal-case tracking-normal text-primary-500"
  >
    <slot></slot>
  </h2>
</template>
