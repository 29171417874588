<script setup>
import { ref } from "vue";

const props = ref({});
</script>

<template>
  <h4
    v-bind="props"
    class="text-[1.625rem] desktop:text-[2rem] steleSmall:text-[2rem] font-bold !leading-tight normal-case tracking-normal text-primary-500"
  >
    <slot></slot>
  </h4>
</template>
