<script setup>
import { onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
let timer;

// Define resetTimer outside of lifecycle hooks
const resetTimer = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  const isDesktop =
    width >= 1800 && width <= 2000 && height >= 900 && height <= 1100;
  const isSteleFullHD =
    (width >= 900 && width <= 1100 && height >= 1800 && height <= 2000) ||
    (height >= 900 && height <= 1100 && width >= 1800 && width <= 2000);
  const isStele4K =
    (width >= 2000 && width <= 2200 && height >= 3600 && height <= 4000) ||
    (height >= 2000 && height <= 2200 && width >= 3600 && width <= 4000);

  if (isDesktop || isSteleFullHD || isStele4K) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      router.push("/");
    }, 1200000); // 20 minutes = 1200000
  }
};

onMounted(() => {
  resetTimer(); // Initialize the timer when component is mounted

  // Add event listeners
  window.addEventListener("mousemove", resetTimer);
  window.addEventListener("click", resetTimer);
  window.addEventListener("keypress", resetTimer);
});

onUnmounted(() => {
  // Clear the timer and remove event listeners
  clearTimeout(timer);
  window.removeEventListener("mousemove", resetTimer);
  window.removeEventListener("click", resetTimer);
  window.removeEventListener("keypress", resetTimer);
});
</script>

<template>
  <router-view v-slot="{ Component, route }">
    <transition appear :name="route.meta.transition || 'fade'">
      <component :is="Component" />
    </transition>
  </router-view>
</template>
