<script setup>
import { ref } from "vue";

const props = ref({});
</script>

<template>
  <h6
    v-bind="props"
    class="text-xl desktop:text-2xl steleSmall:text-2xl font-light !leading-normal normal-case tracking-normal text-primary-500"
  >
    <slot></slot>
  </h6>
</template>
